import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { flightActions } from "../../Stores/flight.store"
import Select from 'react-select'
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"

const To = ({ customStyles }) => {

    const dispatch = useDispatch();
    const [toClicked, setToClicked] = useState(false);

    const to = useSelector(state => state.flight.search.to);
    const cites = useSelector(state => state.flight.cites);

    const onToChange = (e) => {
        dispatch(flightActions.setSearchProp({prop:'to', value: e}));
        setToClicked(false)
    }

    return <div className="col-lg-3 border-end p-3 ps-4" style={{ cursor:'pointer' }}>
        <p className="mb-2 fs-7">TO</p>
        
        {!toClicked && <div onClick={() => setToClicked(true)}>
            <h5 className="mb-0">{to.city}</h5>
            <p className="text-muted mb-0">{to.airport}</p>
        </div>}

        {toClicked && <Select
            styles={customStyles}
            value={to}
            onChange={onToChange}
            options={cites}
            isSearchable={true}
            placeholder="Select"
        />}
    </div>
}

export default To;