import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function ToasterPopup(msg, type='error') {	
	toast(msg, {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
		type: type
	});

	return false;
}