import { Row, Col, Container } from "react-bootstrap";
import FlightSearch from "../Components/FlightSearch";
import FlightSearchList from "../Components/FlightSearchList";

const FlightOldList = () => {
    return <>
        <Row>
            <Col>
                <FlightSearch />
            </Col>
        </Row>

        <Container>
            <Row>
                <Col>
                    <FlightSearchList />
                </Col>
            </Row>
        </Container>
    </>
};

export default FlightOldList;