import { useSelector } from "react-redux";
import FlightDetails from "./FlightDetails";
import { Card,Row,Col,Image} from "react-bootstrap";

const FlightSearchList = () => {
    const list = useSelector(state => state.flight.list);
    const airlines = useSelector(state => state.flight.airlines);

    function findAirLineName(data) {
        return data.AirItinerary.OriginDestinationOptions.OriginDestinationOption.map((x) => {
            return x.FlightSegment.map((y) => {
                let findAirLine = airlines.find((a) => {
                    return a.code === y.OperatingAirline.Code
                })

                return findAirLine ? findAirLine.name : ''
            }).filter(function (v, i, self) {
                return self.indexOf(v) === i;
            }).join(',')
        }).filter(function (v, i, self) {
            return self.indexOf(v) === i;
        })
    }

    function findAirLineCode(data) {
        let links =  data.AirItinerary.OriginDestinationOptions.OriginDestinationOption.map((x) => {
            return x.FlightSegment.map((y) => {
                return y.OperatingAirline.Code
            }).filter(function (v, i, self) {
                return self.indexOf(v) === i;
            }).join(',')
        }).filter(function (v, i, self) {
            return self.indexOf(v) === i;
        }).map((u) => {
            return "https://content.airhex.com/content/logos/airlines_"+u+"_70_30_r.png"
        })

        return links
    }    

    return <>
        {
            !list.length &&
            <Card className="listingCard mt-2 text-center text-muted">
                <Card.Body>
                    <strong>Sorry, Flights Not Found.</strong>
                    <p>We could not find any flights for this search. Please go back to make a different selection.</p>
                </Card.Body>
            </Card>
        }

        {list.map((data, key) => 
            <Card className="listingCard mt-2" key={key}>
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <div className="airlineName">
                            <div className="d-flex justify-content-start align-items-center">
                                <div>
                                    {
                                        findAirLineCode(data).map((url, index) => {
                                            return <Image 
                                                key={index}
                                                className="mr-2 arln-logo" 
                                                src={url} 
                                                thumbnail 
                                           ></Image>
                                        })
                                    }                                
                                </div>
                                <div>
                                    {findAirLineName(data)}                            
                                    <p className="fliCode">
                                        {
                                            data.AirItinerary.OriginDestinationOptions.OriginDestinationOption.map((x) => {
                                                return x.FlightSegment.map((y) => {
                                                    return y.OperatingAirline.Code+' '+y.OperatingAirline.FlightNumber
                                                })
                                            }).join(', ')
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <h5>
                            {data.AirItineraryPricingInfo.ItinTotalFare.TotalFare.CurrencyCode + " "}
                            {data.AirItineraryPricingInfo.ItinTotalFare.TotalFare.Amount}
                        </h5>
                    </div>

                    <Row>
                        <Col>
                            <FlightDetails 
                                type="Depart" 
                                data={data.AirItinerary.OriginDestinationOptions.OriginDestinationOption[0]} 
                            />
                        </Col>

                        <Col>
                            <FlightDetails 
                                type="Return" 
                                data={data.AirItinerary.OriginDestinationOptions.OriginDestinationOption[1]} 
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )}

    </>
}


export default FlightSearchList;