import { Form } from "react-bootstrap";
import { flightActions } from "../../Stores/flight.store";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import moment from "moment";
import { useRef } from "react";

const Depart = () => {
    const dispatch = useDispatch();

    const departDate = useSelector(state => state.flight.search.depart);
    const [depClicked, setDepClicked] = useState(false)
    const dateElement = useRef(null);

    const onDateChanged = (e) => {
        const dateToChange = e.target.value;
        const specificDate = moment(dateToChange, 'YYYY-MM-DD');
        const formattedDate = specificDate.format('DD MMM, YYYY');
        const dayOfWeek = specificDate.format('dddd');

        let dateObj = {
            date: e.target.value,
            formattedDate,
            dayOfWeek
        }

        dispatch(flightActions.setSearchProp({prop:'depart', value: dateObj}))
        setDepClicked(false)
    }

    const onDivClicked = () => {
        setDepClicked(true)
        
        if (dateElement.current) {
            dateElement.current.focus();
        }
    }

    return <div className="col-lg-2 border-end p-3" style={{ cursor:'pointer' }}>
        <p className="mb-2 fs-7">DEPARTURE</p>
        
        {!depClicked && <div onClick={onDivClicked}>
            <h5 className="mb-0">{departDate.formattedDate}</h5>
            <p className="text-muted mb-0">{departDate.dayOfWeek}</p>
        </div> }

        {depClicked && <Form.Control
                ref={dateElement}
                type="date"
                value={departDate.date}
                onChange={onDateChanged}
                onBlur={() => setDepClicked(false)}
            />}
    </div>
}

export default Depart;