import { useEffect } from "react";
import { flightActions } from "../Stores/flight.store";
import { useDispatch } from "react-redux";
import useHttp from "../Hooks/use-http";
import { ToastContainer } from "react-toastify";

const AirLayout = (props) => {
    const dispatch = useDispatch();
    const { makeRequest } = useHttp();

    useEffect(() => {
        fetchCities();
        fetchAllAirlines();
    }, [])

    const fetchCities = () => {
        makeRequest({
            url: '/v1/lists/supported/shop/flights/origins-destinations',
            method: 'GET',
        }, (res) => {
            if(res.OriginDestinationLocations) {
                let allCitesOrg = res.OriginDestinationLocations.map((city) => {
                    return {
                        value : city.OriginLocation.AirportCode,
                        label: city.OriginLocation.AirportCode + ' - ' + city.OriginLocation.AirportName,
                        city: city.OriginLocation.CityName,
                        country: city.OriginLocation.CountryName,
                        airport: city.OriginLocation.AirportName
                    }
                })

                let allCitesDes = res.OriginDestinationLocations.map((city) => {
                    return {
                        value : city.DestinationLocation.AirportCode,
                        label: city.DestinationLocation.AirportCode + ' - ' + city.DestinationLocation.AirportName,
                        city: city.DestinationLocation.CityName,
                        country: city.DestinationLocation.CountryName,
                        airport: city.DestinationLocation.AirportName
                    }
                })

                let allCites = allCitesOrg.concat(allCitesDes);

                let uniqueValues = {};
                let uniqueArray = allCites.filter(obj => {
                    if (!uniqueValues[obj.value]) {
                        uniqueValues[obj.value] = true;
                        return true; 
                    }
                    return false;
                });

                dispatch(flightActions.setCities(uniqueArray));
            }
        });
    }

    const fetchAllAirlines = () => {
        makeRequest({
            url: '/v1/lists/utilities/airlines',
            method: 'GET',
        }, (res) => {
            if(res.AirlineInfo) {
                let allAirlines = res.AirlineInfo.map((air) => {
                    return {
                        code: air.AirlineCode,
                        name: air.AirlineName
                    }
                });
                
                dispatch(flightActions.setAirlines(allAirlines));
            }
        });
    }

    return <div className="header">
        {props.children}
        <ToastContainer />
    </div>
}

export default AirLayout;