import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import Filter from "../Components/FlightSearch/Filter";
import Header from "../Components/FlightSearch/Header";
import AirSearchLayout from "../Layouts/AirSearchLayout";
import OneWayFlight from "../Components/FlightSearch/OneWayFlightList";
import RoundWayFlightList from "../Components/FlightSearch/RoundWayFlightList";

const FlightList = () => {
    const from = useSelector(state => state.flight.search.from);
    const to = useSelector(state => state.flight.search.to);
    const list = useSelector(state => state.flight.list);
    const travelType = useSelector(state => state.flight.search.travelType);

    return <>
        <AirSearchLayout>
            <Header />

            <div className="container py-4">
                <div className="row">
                    <div className="col-md-3">
                        <Filter />
                    </div>

                    <div className="col-md-9">
                        <h5 className="mb-3">Flights from {from.city} to {to.city} {travelType === 'round' ? ', back' : ''}</h5>
                        {
                            !list.length ?
                                <Card className="listingCard mt-2 text-center text-muted">
                                    <Card.Body>
                                        <strong>Sorry, Flights Not Found.</strong>
                                        <p>We could not find any flights for this search. Please go back to make a different selection.</p>
                                    </Card.Body>
                                </Card>
                            :                            
                            travelType === 'round' ?
                                <RoundWayFlightList />
                            :
                                <OneWayFlight />                            
                        }
                    </div>
                </div>
            </div>           
        </AirSearchLayout>
    </>
}

export default FlightList;