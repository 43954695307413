import moment from "moment/moment";
import { Card } from "react-bootstrap";

const FlightDetails = ({type, data}) => {
    let isNonStop = true
    let stopViaAirport =''   
    let totalTime = timeConvert(data.ElapsedTime)    
    let departureTime = moment(data.FlightSegment[0].DepartureDateTime).format("HH:mm")
    let arrivalTime = moment(data.FlightSegment[0].ArrivalDateTime).format("HH:mm")
    let departureAirport = data.FlightSegment[0].DepartureAirport.LocationCode
    let arrivalAirport = data.FlightSegment[0].ArrivalAirport.LocationCode

    if(data.FlightSegment.length > 1) {
        isNonStop = false
        let cloneData = JSON.parse(JSON.stringify(data.FlightSegment))
        cloneData.pop()
        stopViaAirport = cloneData.map((x => { return x.ArrivalAirport.LocationCode})).join(',')
        let lastElement = data.FlightSegment[data.FlightSegment.length-1]
        arrivalTime = moment(lastElement.ArrivalDateTime).format("HH:mm")
        arrivalAirport = lastElement.ArrivalAirport.LocationCode
    }

    function timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var minutes = (hours - Math.floor(hours)) * 60;

        return {
            hours: Math.floor(hours),
            minutes: Math.round(minutes)
        }
    }

    return <>        
        <div>
        <p>
            <b className="mr-2">{type}</b>
            {type === "Depart" ?
            <>                    
                {moment(data.FlightSegment[0].DepartureDateTime).format("ddd,DD MMM")}
            </>
            :
            <>
                {moment(data.FlightSegment[0].ArrivalDateTime).format("ddd,DD MMM")}
            </>
            }
            <span className="ml-2 d-none">• Spirit Airlines</span>            
        </p>
        </div>
        <Card className="fliTiming">
            <Card.Body>
                <div className="d-flex justify-content-between fontSize12">
                    <div className="flexOne timeInfoLeft">
                        <p className="appendBottom2 flightTimeInfo text-left">
                            <span>{departureTime}</span>
                        </p>
                        <p className="blackText">
                            <font color="#000000">{departureAirport}</font>
                        </p>
                    </div>

                    <div className="text-center">
                        <span className="appendBottom2">
                            {totalTime.hours}<font color="#757575"> h </font>
                            {totalTime.minutes}<font color="#757575"> m </font>
                        </span>
                        <div className="relative fliStopsSep">
                            {
                                isNonStop ?
                                <>
                                    <div>
                                        <p className="fliStopsSepLine" style={{borderTop: "3px solid rgb(81, 226, 194)"}}>
                                        </p>
                                        <span className="fliNonStop"></span>
                                    </div>
                                    <span className="flightsLayoverInfo">Non stop</span>
                                </>
                                :
                                <>
                                    <div>
                                        <p className="fliStopsSepLine" style={{borderTop: "3px solid rgb(245, 166, 34)"}}>
                                        </p>
                                        <span className="fliStopsDisc"></span>
                                    </div>
                                    <span className="flightsLayoverInfo">
                                        {data.FlightSegment.length - 1} stop via {stopViaAirport}
                                    </span>
                                </>
                            }

                        </div>
                    </div>

                    <div className="flexOne text-right">
                        <p className="appendBottom2 flightTimeInfo">
                            <span>{arrivalTime}</span>
                        </p>
                        <p className="blackText">
                            <font color="#000000">{arrivalAirport}</font>
                        </p>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </>    
}

export default FlightDetails;