import { ThreeCircles } from 'react-loader-spinner';

export default function AppSpinner({ visible }) {
	return (
		visible && (
			<>
				<div className="spinner_overlay"></div>
				<ThreeCircles
					height="100"
					width="100"
					color="#4fa94d"
					wrapperStyle={{}}
					wrapperClass="spinner"
					ariaLabel="three-circles-rotating"
					outerCircleColor="#185395"
					innerCircleColor="#1166f5"
					middleCircleColor="#48a5fc"
					visible={visible}
				/>
			</>
		)
	);
}
