import logo from "../../Assets/images/logo.png";

const NavBar = () => {
    return <div className="navbar container shadow-sm">
        <img src={logo} width="200px" />
        <div>
            <button className="btn btn-sm btn-success-gradient">
                <i className="fas fa-sign-in-alt me-2"></i>Login or Create Account</button>
        </div>
    </div>
}

export default NavBar;