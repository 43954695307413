
import store from './Stores';
import { Provider } from 'react-redux';
import FlightList from './Pages/FlightList';
import Flights from './Pages/Flights';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/css/style.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FlightOldList from './Pages/FlightOldList';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Flights />} />
          <Route path='/flight/search' element={<FlightList />} />
          <Route path='custom/flight/search' element={<FlightOldList />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
