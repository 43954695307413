import { useSelector } from "react-redux";
import { flightActions } from "../../Stores/flight.store";
import { useDispatch } from "react-redux";
import { useState } from "react";
import moment from "moment";
import { useRef } from "react";
import { Form } from "react-bootstrap";

const Return = () => {
    const dispatch = useDispatch();
    const tripType = useSelector(state => state.flight.search.travelType);

    const returnDate = useSelector(state => state.flight.search.return);
    const [isClicked, setIsClicked] = useState(false)
    const dateElement = useRef(null);

    const onDateChanged = (e) => {
        const dateToChange = e.target.value;
        const specificDate = moment(dateToChange, 'YYYY-MM-DD');
        const formattedDate = specificDate.format('DD MMM, YYYY');
        const dayOfWeek = specificDate.format('dddd');

        let dateObj = {
            date: e.target.value,
            formattedDate,
            dayOfWeek
        }

        dispatch(flightActions.setSearchProp({prop:'return', value: dateObj}))
        setIsClicked(false)
    }

    const onDivClicked = () => {
        setIsClicked(true)
        
        if (dateElement.current) {
            dateElement.current.focus();
        }
    }

    return <div className="col-lg-2 border-end p-3" style={{ cursor:'pointer' }}>
        <p className="mb-2 fs-7">RETURN</p>
        {tripType === 'oneway' && 
            <p className="text-muted mb-0"
                onClick={() => dispatch(flightActions.setSearchProp({prop:'travelType', value: 'round'}))}
            > Tap to add a return date for bigger discounts</p>}

        {tripType === 'round' && !isClicked && <div onClick={onDivClicked}>
            <h5 className="mb-0">{returnDate.formattedDate}</h5>
            <p className="text-muted mb-0">{returnDate.dayOfWeek}</p>
        </div> }

        {tripType === 'round' && isClicked && <Form.Control
                ref={dateElement}
                type="date"
                value={returnDate.date}
                onChange={onDateChanged}
                onBlur={() => setIsClicked(false)}
            />}
    </div>
};

export default Return;