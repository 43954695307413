import { flightActions } from "../Stores/flight.store";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import useHttp from "../Hooks/use-http";
import { useEffect, useState } from "react";
import Select from 'react-select';
import AppSpinner from "./Common/AppSpinner";

const FlightSearch = () => {
    const dispatch = useDispatch();
    const { makeRequest } = useHttp();
    const from = useSelector(state => state.flight.search.from);
    const to = useSelector(state => state.flight.search.to);
    const cites = useSelector(state => state.flight.cites);
    const departDate = useSelector(state => state.flight.search.depart);
    const returnDate = useSelector(state => state.flight.search.return);
    const passenger = useSelector(state => state.flight.search.passenger);  

    const [spinner, setSpinner] = useState(false)

    useEffect(() => {
        fetchCities();
        fetchAllAirlines();
        // eslint-disable-next-line
    }, [])

    const fetchCities = () => {
        makeRequest({
            url: '/v1/lists/supported/shop/flights/origins-destinations',
            method: 'GET',
        }, (res) => {
            if(res.OriginDestinationLocations) {
                let allCitesOrg = res.OriginDestinationLocations.map((city) => {
                    return {
                        value : city.OriginLocation.AirportCode,
                        label: city.OriginLocation.AirportCode + ' - ' + city.OriginLocation.AirportName
                    }
                })

                let allCitesDes = res.OriginDestinationLocations.map((city) => {
                    return {
                        value : city.DestinationLocation.AirportCode,
                        label: city.DestinationLocation.AirportCode + ' - ' + city.DestinationLocation.AirportName
                    }
                })

                let allCites = allCitesOrg.concat(allCitesDes);

                let uniqueValues = {};
                let uniqueArray = allCites.filter(obj => {
                    if (!uniqueValues[obj.value]) {
                        uniqueValues[obj.value] = true;
                        return true; 
                    }
                    return false;
                });

                dispatch(flightActions.setCities(uniqueArray));
            }
        });
    }

    const fetchAllAirlines = () => {
        makeRequest({
            url: '/v1/lists/utilities/airlines',
            method: 'GET',
        }, (res) => {
            if(res.AirlineInfo) {
                let allAirlines = res.AirlineInfo.map((air) => {
                    return {
                        code: air.AirlineCode,
                        name: air.AirlineName
                    }
                });
                
                dispatch(flightActions.setAirlines(allAirlines));
            }
        });
    }

    const searchFights = () => {
        setSpinner(true)
        makeRequest({
            url: '/v1/shop/flights',
            method: 'GET',
            body: {
                origin: from.value,
                destination : to.value,
                departuredate  : departDate,
                returndate: returnDate,
                sortby: 'totalfare',
                order: 'asc',
                order2: 'asc',
                enabletagging: false
            }
        }, (response) => {
            let responseData = response && response.PricedItineraries ? response.PricedItineraries : []
            dispatch(flightActions.setProp({prop:'list', value: responseData}))
            setSpinner(false)
        })
    }

    return <>
        <Card className="bgGradient">
            <Card.Body>
                <Row>
                    <Col>
                        <Form.Label className="blueText">From</Form.Label>

                        <Select
                            value={from}
                            onChange={(e) => dispatch(flightActions.setSearchProp({prop:'from', value: e})) }
                            options={cites}
                            isSearchable={true}
                            placeholder="Select"
                        />
                    </Col>
                    <Col>
                        <Form.Label className="blueText">To</Form.Label>

                        <Select
                            value={to}
                            onChange={(e) => dispatch(flightActions.setSearchProp({prop:'to', value: e}))}
                            options={cites}
                            isSearchable={true}
                            placeholder="Select"
                        />
                    </Col>
                    <Col>
                        <Form.Label className="blueText">DEPART</Form.Label>
                        <Form.Control
                            type="date"
                            value={departDate}
                            onChange={(e) => dispatch(flightActions.setSearchProp({prop:'depart', value: e.target.value}))}
                        />
                    </Col>
                    <Col>
                        <Form.Label className="blueText">RETURN</Form.Label>
                        <Form.Control
                            type="date"
                            value={returnDate}
                            onChange={(e) => dispatch(flightActions.setSearchProp({prop:'return', value: e.target.value}))}
                        />
                    </Col>
                    <Col>
                        <Form.Label className="blueText">PASSENGERS & CLASS</Form.Label>
                        <Form.Control
                            type="number"
                            value={passenger}
                            onChange={(e) => dispatch(flightActions.setSearchProp({prop:'passenger', value: e.target.value}))}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-3">
                        <Button className="search-btn" onClick={searchFights}>Search</Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>

        <AppSpinner visible={spinner} />
    </>
};

export default FlightSearch;