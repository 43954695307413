import moment from "moment";
import useHttp from "../../Hooks/use-http";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { flightActions } from "../../Stores/flight.store";
import { ToasterPopup } from "../Common/ToasterPopup";
import { useEffect } from "react";

const SearchButton = () => {
    const { makeRequest } = useHttp();
    const dispatch = useDispatch();
    
    let navigate = useNavigate(); 
    const from = useSelector(state => state.flight.search.from);
    const to = useSelector(state => state.flight.search.to);
    const departDate = useSelector(state => state.flight.search.depart);
    const returnDate = useSelector(state => state.flight.search.return);
    const tripType = useSelector(state => state.flight.search.travelType);
    const passenger = useSelector(state => state.flight.search.passenger);

    const onSearch = () => {
        
        let rDate = returnDate.date;

        if(tripType === 'oneway') {
            rDate = moment(departDate.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
        }

        const date1 = moment(departDate.date, 'YYYY-MM-DD');
        const date2 = moment(rDate, 'YYYY-MM-DD');

        if(tripType === 'round' && date1.isAfter(date2)) {
            ToasterPopup('Return date must be on or after departure date');
            return;
        }

        let data = {
            origin: from.value,
            destination: to.value,
            departuredate  : departDate.date,
            returndate: rDate,
            sortby: 'totalfare',
            order: 'asc',
            enabletagging: false,
            passengercount: passenger
        }

        makeRequest({
            url: '/v1/shop/flights',
            method: 'GET',
            body: data
        }, (response) => {
            let responseData = response && response.PricedItineraries ? response.PricedItineraries : []
            dispatch(flightActions.setProp({prop:'list', value: responseData}))

            navigate('flight/search')
        })
    }

    return <div className="card-search">
        <button className="btn btn-success-gradient rounded-pill" onClick={onSearch}>Search</button>
    </div>
}

export default SearchButton;