import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { flightActions } from "../../Stores/flight.store";
import { useSelector } from "react-redux";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { useState } from "react";
import From from "./From";
import To from "./To";
import SearchButton from "./SearchButton";
import { Form } from "react-bootstrap";
import Depart from "./Depart";
import Return from "./Return";
import Passengers from "./Passengers";

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '90%', // Adjust the width as needed
      height: '20px', // Adjust the height as needed
      fontSize: '13px', // Adjust the font size as needed
    }),
  };

const Search = () => {
    const dispatch = useDispatch();
    const [fromClicked, setFromClicked] = useState(false);

    const from = useSelector(state => state.flight.search.from);
    const to = useSelector(state => state.flight.search.to);
    const cites = useSelector(state => state.flight.cites);
    const departDate = useSelector(state => state.flight.search.depart);
    const returnDate = useSelector(state => state.flight.search.return);
    const passenger = useSelector(state => state.flight.search.passenger);  
    const selectedOption = useSelector(state => state.flight.search.travelType);

    const onRadioChanged = (e) => {
        dispatch(flightActions.setSearchProp({prop:'travelType', value: e.target.value}));
    }

    return <div className="card-body px-4 py-5">
        <div className="btn-group btn-group-sm mb-4" role="group">
            <input type="radio" className="btn-check" name="flightWay" value="oneway" id="OneWay" 
                checked={selectedOption === 'oneway'} 
                onChange={onRadioChanged}
            />
            <label className="btn btn-sm btn-outline-info" htmlFor="OneWay">One Way</label>

            <input type="radio" className="btn-check" name="flightWay" value="round" id="RoundTrip" 
                checked={selectedOption === 'round'} 
                onChange={onRadioChanged}
            />
            <label className="btn btn-sm btn-outline-info" htmlFor="RoundTrip">Round Trip</label>
        </div>

        <div className="container-fluid mb-3">
            <div className="row border rounded-4">
                <From customStyles={customStyles} />
                <To customStyles={customStyles} />
                <Depart />
                <Return />
                <Passengers />
            </div>
        </div>

        <SearchButton />
    </div>
}

export default Search;