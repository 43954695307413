import { faPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useSelector } from "react-redux";

const RoundWayFlightList = () => {
    const cites = useSelector(state => state.flight.cites);
    const airlines = useSelector(state => state.flight.airlines);    
    const list = useSelector(state => state.flight.list);
    const passenger = useSelector(state => state.flight.search.passenger);

    function findAirLineName(data) {
        return data.AirItinerary.OriginDestinationOptions.OriginDestinationOption.map((x) => {
            return x.FlightSegment.map((y) => {
                return findAirLine(y.OperatingAirline.Code)
            }).filter(function (v, i, self) {
                return self.indexOf(v) === i;
            }).join(', ')
        }).filter(function (v, i, self) {
            return self.indexOf(v) === i;
        })
    }

    function findAirLine(code) {
        let airline = airlines.find((a) => {
            return a.code === code
        })

        return airline ? airline.name : ''
    }

    function findCity(code, includeCode = true) {
        let city = cites.find((a) => {
            return a.value === code
        })

        if(includeCode) {
            return city ? city.city + ' ('+code+')' : code
        }

        return city ? city.city : code
    }

    function flightStops(oneWayData) { 
        let totalTime = timeConvert(oneWayData.ElapsedTime)   

        if(oneWayData.FlightSegment.length > 1) {
            let cloneData = JSON.parse(JSON.stringify(oneWayData.FlightSegment))
            cloneData.pop()
            let stopViaAirport = cloneData.map((x => { return findCity(x.ArrivalAirport.LocationCode, false)})).join(', ')

            return <>
                <h6 className="mb-0 text-info">{totalTime.hours}h {totalTime.minutes}m</h6>
                <div className="non-stop fst-italic" style={{color: '#ff772d'}}>
                    {oneWayData.FlightSegment.length - 1} stop via {stopViaAirport}
                </div>
            </>
        }

        return <>
            <h6 className="mb-0 text-info">{totalTime.hours}h {totalTime.minutes}m</h6>
            <div className="non-stop fst-italic text-success">Non stop</div>
        </>
    }

    function timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var minutes = (hours - Math.floor(hours)) * 60;

        return {
            hours: Math.floor(hours),
            minutes: Math.round(minutes)
        }
    }

    function flightDepartureCard(data, type) {
        let departureTime = moment(data.FlightSegment[0].DepartureDateTime).format("HH:mm")
        let arrivalTime = moment(data.FlightSegment[0].ArrivalDateTime).format("HH:mm")
        let departureAirport = data.FlightSegment[0].DepartureAirport.LocationCode
        let arrivalAirport = data.FlightSegment[0].ArrivalAirport.LocationCode

        if(data.FlightSegment.length > 1) {
            let lastElement = data.FlightSegment[data.FlightSegment.length-1]
            arrivalTime = moment(lastElement.ArrivalDateTime).format("HH:mm")
            arrivalAirport = lastElement.ArrivalAirport.LocationCode
        }

        return <>
            <div className="col-lg-6">
                <p className="text-muted fw-semibold mb-1" style={{fontSize: '0.85rem'}}>
                    <span className="text-dark">{type}: </span>
                    {moment(data.FlightSegment[0].DepartureDateTime).format("DD MMM, ddd")}
                    <span className="border-start ps-2 ms-2">
                        <FontAwesomeIcon icon={faPlane} className="me-1"></FontAwesomeIcon>
                        {findAirLine(data.FlightSegment[0].OperatingAirline.Code)}
                    </span>
                </p>
                <div
                    className="d-flex justify-content-between align-items-center p-2 bg-light rounded">
                    <div>
                        <h6 className="mb-0">{departureTime}</h6>
                        <div className="sub-list">{findCity(departureAirport, true)}</div>
                    </div>

                    <div>
                        {flightStops(data)}
                    </div>                    

                    <div>
                        <h6 className="mb-0">{arrivalTime}</h6>
                        <div className="sub-list">{findCity(arrivalAirport, true)}</div>
                    </div>
                </div>
            </div>
        </>
    }    

    return <>
        {list.map((data, key) =>
            <div className="card shadow-sm border-0 mb-3">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h6 className="mb-1">{findAirLineName(data)}</h6>
                            <p className="mb-0 text-muted fs-7 fw-semibold">
                                {
                                    data.AirItinerary.OriginDestinationOptions.OriginDestinationOption.map((x) => {
                                        return x.FlightSegment.map((y) => {
                                            return y.OperatingAirline.Code+' '+y.OperatingAirline.FlightNumber
                                        }).join(', ')
                                    }).join(', ')
                                }
                            </p>
                        </div>

                        <div className="d-flex align-items-center">
                            <h6 className="mb-0 me-2">
                                {data.AirItineraryPricingInfo.ItinTotalFare.TotalFare.CurrencyCode + " "}
                                {(data.AirItineraryPricingInfo.ItinTotalFare.TotalFare.Amount*passenger).toFixed(2)}
                            </h6>
                            <button className="btn btn-sm btn-outline-success rounded-pill btn-book px-3">
                                Book Now
                            </button>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="row">
                        {flightDepartureCard(data.AirItinerary.OriginDestinationOptions.OriginDestinationOption[0], 'Depart')}
                        {flightDepartureCard(data.AirItinerary.OriginDestinationOptions.OriginDestinationOption[1], 'Return')}
                    </div>
                </div>
            </div>
        )}
    </>
}

export default RoundWayFlightList;
