import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { flightActions } from "../../Stores/flight.store"
import Select from 'react-select'
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"

const From = ({ customStyles }) => {

    const dispatch = useDispatch();
    const [fromClicked, setFromClicked] = useState(false);

    const from = useSelector(state => state.flight.search.from);
    const cites = useSelector(state => state.flight.cites);

    const onFromChange = (e) => {
        dispatch(flightActions.setSearchProp({prop:'from', value: e}));
        setFromClicked(false)
    }

    return <div className="col-lg-3 border-end position-relative p-3" 
        style={{ cursor:'pointer' }}
    >
        <p className="mb-2 fs-7">FROM</p>
        
        {!fromClicked && <div onClick={() => setFromClicked(true)}>
            <h5 className="mb-0">{from.city}</h5>
            <p className="text-muted mb-0">{from.airport}</p>
        </div>}

        {fromClicked && <Select
            styles={customStyles}
            value={from}
            onChange={onFromChange}
            options={cites}
            isSearchable={true}
            placeholder="Select"
        />}

        <div className="btn-reverse">
            <button className="btn btn-sm shadow-sm rounded-pill">
                <FontAwesomeIcon icon={faExchangeAlt} size="sm" />
            </button>
        </div>
    </div>
}

export default From;