import moment from "moment";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
    const from = useSelector(state => state.flight.search.from);
    const to = useSelector(state => state.flight.search.to);
    const departDate = useSelector(state => state.flight.search.depart.date);
    const returnDate = useSelector(state => state.flight.search.return.date);
    const passenger = useSelector(state => state.flight.search.passenger); 
    const travelType = useSelector(state => state.flight.search.travelType); 

    return <>
        <div className="workspace-header">
            <div className="container py-4">
                <div className="row">
                    <div className="col-lg-2">
                        <div className="card shadow-sm border-0">
                            <div className="card-body p-2">
                                <p className="fs-7 text-info mb-1 fw-bold">TRIP TYPE</p>
                                <p className="mb-0 fw-semibold">{travelType === 'round' ? 'Round Trip' : 'One Way'}</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-lg-4 d-flex align-items-center">
                        <div className="card shadow-sm border-0 w-50">
                            <div className="card-body p-2">
                                <p className="fs-7 text-info mb-1 fw-bold">FROM</p>
                                <p className="mb-0 fw-semibold">{from.city}</p>
                            </div>
                        </div>
    
                        <div className="mx-2">
                            <FontAwesomeIcon className="text-success" icon={faExchangeAlt}></FontAwesomeIcon>
                        </div>
    
                        <div className="card shadow-sm border-0 w-50">
                            <div className="card-body p-2">
                                <p className="fs-7 text-info mb-1 fw-bold">TO</p>
                                <p className="mb-0 fw-semibold">{to.city}</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-lg-2">
                        <div className="card shadow-sm border-0">
                            <div className="card-body p-2">
                                <p className="fs-7 text-info mb-1 fw-bold">DEPART</p>
                                <p className="mb-0 fw-semibold">{moment(departDate).format('ddd, DD MMM, YYYY')}</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-lg-2">
                        <div className="card shadow-sm border-0">
                            <div className="card-body p-2">
                                <p className="fs-7 text-info mb-1 fw-bold">RETURN</p>
                                <p className="mb-0 fw-semibold">
                                    {
                                        travelType === 'round' ?
                                        moment(returnDate).format('ddd, DD MMM, YYYY')
                                        :
                                        <span className="text-muted mb-0 fw-semibold">Select Return</span>
                                    }
                                    </p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-lg-2">
                        <div className="card shadow-sm border-0">
                            <div className="card-body p-2">
                                <p className="fs-7 text-info mb-1 fw-bold">PASSENGERS & CLASS</p>
                                <p className="mb-0 fw-semibold">{passenger} Adult, Economy</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Header;