import NavBar from "../Components/Flights/NavBar";
import Search from "../Components/Flights/Search";
import AirLayout from "../Layouts/AirLayout";

const Flights = () => {
    return <>
        <AirLayout>
            <NavBar />
            
            <div className="container my-5">
                <div className="card rounded-4 position-relative shadow-sm">
                    <div className="card-header border-bottom-0 py-3">
                        <h5 className="text-center mb-0">Book International and Domestic Flights</h5>
                    </div>
                    
                    <Search />
                </div>
            </div>
        </AirLayout>
    </>
}

export default Flights;