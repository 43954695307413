import moment from "moment";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const DateRange = () => {
    const departDate = useSelector(state => state.flight.search.depart.date);   
    const startDate = moment(departDate).subtract(1, "days").format('YYYY-MM-DD'); 
    const endDate = moment(startDate).add(6, "days").format('YYYY-MM-DD'); 

    const getDateRange = (firstDate, lastDate) => {
        if (moment(firstDate, 'YYYY-MM-DD').isSame(moment(lastDate, 'YYYY-MM-DD'), 'day'))
          return [lastDate];
        let date = firstDate;
        const dates = [date];
        do {
          date = moment(date).add(1, 'day');
          dates.push(date.format('YYYY-MM-DD'));
        } while (moment(date).isBefore(lastDate));
        return dates;
    };

    const dates = getDateRange(startDate, endDate)   

    return <>
        <div className="list-week" title="coming soon" style={{ cursor: 'default' }}>
            <div className="left-arrow">
                <FontAwesomeIcon icon={faChevronLeft} className="text-primary"></FontAwesomeIcon>
            </div>

            {dates.map((date, index) => (
                <div key={index} className={date === departDate ? 'active' : ''}>
                    {moment(date).format('DD MMM, ddd')}
                </div>
            ))}
            <div className="right-arrow">
                <FontAwesomeIcon icon={faChevronRight} className="text-primary"></FontAwesomeIcon>
            </div>
        </div>
    </>
}

export default DateRange;