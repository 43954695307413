import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { flightActions } from "../../Stores/flight.store";

const Passengers = () => {
    const passenger = useSelector(state => state.flight.search.passenger);
    const [isClicked, setIsClicked] = useState(false)
    const dispatch = useDispatch();

    const passChanged = (event) => {
        dispatch(flightActions.setSearchProp({prop:'passenger', value: event.target.value}));
    }

    return <div className="col-lg-2 py-3">
        <p className="mb-2 fs-7">TRAVELLERS & CLASS</p>

        {!isClicked && <div onClick={() => setIsClicked(true)}>
            <h5 className="mb-0">{passenger} Traveller</h5>
        </div>}

        {isClicked && <div>
            <input type="number" 
                className="form-control form-control-sm" 
                value={passenger} 
                onChange={passChanged} 
                onBlur={() => setIsClicked(false)}
            />
        </div>}

        {/* <p className="text-muted mb-0">Economy/Premium Economy</p> */}
    </div>
};

export default Passengers;