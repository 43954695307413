import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Filter = () => {
    return <>
        <div className="card shadow-sm border-0" title="coming soon">
            <div className="card-body">
                <h6 className="d-flex justify-content-between align-items-center mb-3">
                    Filters <span className="text-primary fs-7">Clear All</span>
                </h6>                

                <div className="filter-header">
                    <FontAwesomeIcon icon={faChevronDown} className="me-2"/>Stops From
                </div>
                <div className="filter-body mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="nonStop" defaultChecked />
                            <label className="form-check-label" htmlFor="nonStop">Non Stop</label>
                        </div>

                        <div className="filter-amount">USD xxx.xx</div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="oneStop" defaultChecked/>
                            <label className="form-check-label" htmlFor="oneStop">1 Stop</label>
                        </div>

                        <div className="filter-amount">USD xxx.xx</div>
                    </div>
                </div>

                <div className="filter-header">
                    <FontAwesomeIcon icon={faChevronDown} className="me-2"/>Airlines
                </div>
                <div className="filter-body">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="spiritAirlines" />
                            <label className="form-check-label" htmlFor="spiritAirlines">Airlines 1</label>
                        </div>

                        <div className="filter-amount">USD xxx.xx</div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="indiGo" />
                            <label className="form-check-label" htmlFor="indiGo">Airlines 2</label>
                        </div>

                        <div className="filter-amount">USD xxx.xx</div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="deltaAirlines" />
                            <label className="form-check-label" htmlFor="deltaAirlines">Airlines 3</label>
                        </div>

                        <div className="filter-amount">USD xxx.xx</div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="airArabia" />
                            <label className="form-check-label" htmlFor="airArabia">Airlines 4</label>
                        </div>

                        <div className="filter-amount">USD xxx.xx</div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="americanAirlines" />
                            <label className="form-check-label" htmlFor="americanAirlines">Airlines 5</label>
                        </div>

                        <div className="filter-amount">USD xxx.xx</div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Filter;