import moment from "moment";
import DateRange from "./DateRange";
import { useSelector } from "react-redux";

const OneWayFlight = () => {   
    const list = useSelector(state => state.flight.list);
    const cites = useSelector(state => state.flight.cites);
    const airlines = useSelector(state => state.flight.airlines);

    function findAirLineName(data) {
        let oneWayData = data.AirItinerary.OriginDestinationOptions.OriginDestinationOption[0]

        return oneWayData.FlightSegment.map((y) => {
            let findAirLine = airlines.find((a) => {
                return a.code === y.OperatingAirline.Code
            })

            return findAirLine ? findAirLine.name : ''
        }).filter(function (v, i, self) {
            return self.indexOf(v) === i;
        }).join(', ')
    }

    function findFlightNumbers(data) {
        let oneWayData = data.AirItinerary.OriginDestinationOptions.OriginDestinationOption[0]

        if(oneWayData) {
            return oneWayData.FlightSegment.map((y) => {
                return y.OperatingAirline.Code+' '+y.OperatingAirline.FlightNumber
            }).join(', ')
        }

        return ''
    }

    function findCity(code, includeCode = true) {
        let city = cites.find((a) => {
            return a.value === code
        })

        if(includeCode) {
            return city ? city.city + ' ('+code+')' : code
        }

        return city ? city.city : code
    }

    function departureTime(data) {
        let oneWayData = data.AirItinerary.OriginDestinationOptions.OriginDestinationOption[0]

        return <>
            <h6 className="mb-0">{moment(oneWayData.FlightSegment[0].DepartureDateTime).format("HH:mm")}</h6>
            <div className="sub-list">{findCity(oneWayData.FlightSegment[0].DepartureAirport.LocationCode, true)}</div>
        </>
    }

    function arrivalTime(data) {
        let oneWayData = data.AirItinerary.OriginDestinationOptions.OriginDestinationOption[0]
        let arrivalTime = moment(oneWayData.FlightSegment[0].ArrivalDateTime).format("HH:mm")
        let arrivalAirport = findCity(oneWayData.FlightSegment[0].ArrivalAirport.LocationCode, true)

        if(oneWayData.FlightSegment.length > 1) {
            let lastElement = oneWayData.FlightSegment[oneWayData.FlightSegment.length-1]
            arrivalTime = moment(lastElement.ArrivalDateTime).format("HH:mm")
            arrivalAirport = findCity(lastElement.ArrivalAirport.LocationCode, true)
        }

        return <>
            <h6 className="mb-0">{arrivalTime}</h6>
            <div className="sub-list">{arrivalAirport}</div>
        </>
    }

    function flightStops(data) {
        let oneWayData = data.AirItinerary.OriginDestinationOptions.OriginDestinationOption[0]
        let totalTime = timeConvert(oneWayData.ElapsedTime)   

        if(oneWayData.FlightSegment.length > 1) {
            let cloneData = JSON.parse(JSON.stringify(oneWayData.FlightSegment))
            cloneData.pop()
            let stopViaAirport = cloneData.map((x => { return findCity(x.ArrivalAirport.LocationCode, false)})).join(', ')

            return <>
                <h6 className="mb-0 text-info">{totalTime.hours}h {totalTime.minutes}m</h6>
                <div className="non-stop fst-italic" style={{color: '#ff772d'}}>
                    {oneWayData.FlightSegment.length - 1} stop via {stopViaAirport}
                </div>
            </>
        }

        return <>
            <h6 className="mb-0 text-info">{totalTime.hours}h {totalTime.minutes}m</h6>
            <div className="non-stop fst-italic text-success">Non stop</div>
        </>
    }

    function timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var minutes = (hours - Math.floor(hours)) * 60;

        return {
            hours: Math.floor(hours),
            minutes: Math.round(minutes)
        }
    }

    return <>
        <DateRange />
        <div className="card shadow-sm border-0">
            <div className="card-body">
                <table className="table table-sm table-flight mb-0">
                    <thead>
                        <tr>
                            <th width="19%">Airlines</th>
                            <th width="18%">Departure</th>
                            <th width="18%">Duration</th>
                            <th width="18%">Arrival</th>
                            <th width="14%">Price</th>
                            <th width="13%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((data, key) => 
                            <tr key={key}>
                                <td>
                                    <h6 className="mb-0">{findAirLineName(data)}</h6>
                                    <div className="sub-list">{findFlightNumbers(data)}</div>
                                </td>
                                <td>{departureTime(data)}</td>
                                <td>{flightStops(data)}</td>
                                <td>{arrivalTime(data)}</td>
                                <td>
                                    <h6 className="mb-0">
                                        {data.AirItineraryPricingInfo.ItinTotalFare.TotalFare.CurrencyCode + " "}
                                        {(data.AirItineraryPricingInfo.ItinTotalFare.TotalFare.Amount / 2).toFixed(2)}
                                    </h6>
                                    <div className="sub-list">per adult</div></td>
                                <td className="text-center">
                                    <button 
                                        className="btn btn-sm btn-outline-success rounded-pill px-3" 
                                        title="coming soon"
                                        >
                                        Book Now
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    </>

}

export default OneWayFlight;