import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const flightStore = createSlice({
    name: 'flight',
    initialState: {
        search : {
            from: {
                value: 'ATL', label: 'ATL - Atlantic City', city: 'Atlanta', airport: 'Hartsfield-Jackson Atlanta International'
            },
            to: {
                value: 'LAS', label: 'LAS - Los Vegas', city: 'Los Vegas', airport: 'McCarran International Airport'
            },
            depart: {
                date:  moment().format('YYYY-MM-DD'),
                formattedDate: moment().format('DD MMM, YYYY'),
                dayOfWeek: moment().format('dddd')
            },
            return: {
                date:  moment().format('YYYY-MM-DD'),
                formattedDate: moment().format('DD MMM, YYYY'),
                dayOfWeek: moment().format('dddd')
            },
            passenger: 1,
            travelType: "oneway",
        },
        list : [],
        cites: [],
        airlines: [],
    },
    reducers: {
        setSearchProp(state, data) {
            state.search[data.payload.prop] = data.payload.value
        },
        setProp(state, data) {
            state[data.payload.prop] = data.payload.value
        },
        setCities(state, data) {
            state.cites = data.payload
        },
        setAirlines(state, data) {
            state.airlines = data.payload
        }
    },
});

export default flightStore.reducer;
export const flightActions = flightStore.actions;