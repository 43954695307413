import logo from "../Assets/images/logo.png";
import { useNavigate } from "react-router-dom";

const AirSearchLayout = (props) => { 
    const navigate = useNavigate();

    return <>
        <div className="navbar navbar-line shadow-sm">
            <div className="container">
                <img src={logo} alt={logo} width="200px" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />

                <div>
                    <button className="btn btn-sm btn-success-gradient">
                        <i className="fas fa-sign-in-alt me-2"></i>Login or Create Account</button>
                </div>
            </div>
        </div>

        <div className="workspace">
            {props.children}
        </div>
    </>

}

export default AirSearchLayout;