import AppConst from "../AppConstants";

const useHttp = () => {
    const baseUrl = 'https://api.cert.platform.sabre.com';

    const objectToUrlParams = (obj) => {
        return Object.keys(obj)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
            .join('&');
    }

    const  makeRequest = async (configData, applyResponse) => {
        try {
            let apiUrl = baseUrl + configData.url;
            let method = configData.method || 'GET';

            if(method === 'GET' && configData.body) {
                let extraParam = objectToUrlParams(configData.body);
                apiUrl += '?' + extraParam;
            }

            const response = await fetch(apiUrl, {
                method : method,
                body : (method !== 'GET' && configData.body) ? JSON.stringify(configData.body) : null,
                headers : {
                    Authorization : AppConst.AccessToken
                }
            })

            if (!response.ok) {
                throw new Error('Request failed!');
            }
        
            const data = await response.json();
            applyResponse(data);
        } catch (err) {
            applyResponse(null);
            //setError(err.message || 'There is some error');
        }        
    }

    return {
        makeRequest
    }
}

export default useHttp;